<template>
    <div class="icon-container">
        <ion-icon :size="size" :color="color" :icon="iconName" :class="{ 'with-shadow': shadow }" />
        <span v-if="badge && size == 'large'" class="badge">{{ badge }}</span>
    </div>
</template>

<script>
import { IonIcon } from '@ionic/vue';

export default {
    components: { IonIcon },
    props: {
        iconName: String.isRequired,
        size: {
            type: String,
            default: 'medium'
        },
        color: {
            type: String,
            default: 'secondary'
        },
        badge: {
            type: [String, Number],
            default: null
        },
        shadow: {
            type: Boolean,
            default: false
        }
    },
}
</script>

<style scoped>
.icon-container {
    position: relative;
    cursor: pointer;
}

.with-shadow {
    filter: drop-shadow(-2px 2px 2px rgba(0, 0, 0, .71));
}

.badge {
    position: absolute;
    left: 20px;
    bottom: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 50%;
    font-size: 0.75rem;
    text-align: center;
    height: 20px;
    width: 20px;
    line-height: 20px;
    font-size: 1.05rem;
}
</style>
